import React from "react";
import PropTypes from "prop-types";

import "assets/stylesheets/application.scss";

import Head from "components/Head";
import Header from "components/Header";
import Footer from "components/Footer";

const Layout = ({ location, children, pageName }) => {
  return (
    <div className="layout">
      <Head />
      <Header location={location} />
      <div className="container-fluid">{children}</div>
      <Footer location={location} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
};

export default Layout;
