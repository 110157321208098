import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import CardMedia from "@material-ui/core/CardMedia";
import { getSrc } from "gatsby-plugin-image";
import { Link } from "gatsby-theme-material-ui";
import { useSiteMetadata } from "hooks";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Row, Item } from "@mui-treasury/components/flex";
import Grid from "@material-ui/core/Grid";
import { NavMenu, NavItem } from "@mui-treasury/components/menu/navigation";
import addToMailchimp from "gatsby-plugin-mailchimp";
import FormLabel from "@material-ui/core/FormLabel";
import {
    EmailSubscribe,
    EmailTextInput,
    SubmitButton,
} from "@mui-treasury/components/EmailSubscribe";
import Snackbar from "@material-ui/core/Snackbar";
import {
    SocialProvider,
    SocialLink,
} from "@mui-treasury/components/socialLink";

import { useMoonSocialLinkStyles } from "@mui-treasury/styles/socialLink/moon";
import { useBootstrapEmailSubscribeStyles } from "@mui-treasury/styles/emailSubscribe/bootstrap";

import Logo from "../assets/svg/light-logo-symbol.svg";

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
    social: {
        maxWidth: 654,
    },
    newsletter: {
        fontSize: typography.caption.fontSize,
        textAlign: "right",
    },
    navMenu: {
        flexWrap: "wrap",
    },
    logo: {
        color: "#1242de",
    },
    copyr: {
        [breakpoints.up("md")]: {
            display: "flex",
            justifyContent: "flex-end",
        },
    },
    subBox: {
        flexDirection: "column",
        [breakpoints.up("md")]: {
            alignItems: "flex-end",
            display: "flex",
            justifyContent: "flex-end",
        },
    },
    email: {
        maxWidth: "300px",
    },
    socialMedia: {
        marginTop: "1em",
    },
}));

const Footer = () => {
    const {
        socialProof: { edges: socialproof },
    } = useStaticQuery(graphql`
        query FooterPageQuery {
            socialProof: allFile(
                filter: { sourceInstanceName: { eq: "socialproof" } }
            ) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData
                        }
                        name
                    }
                }
            }
        }
    `);
    const { companyName } = useSiteMetadata();
    const classes = useStyles();
    const [confirmed, setConfirmed] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setConfirmed(false);
    };

    const styledSocialMedia = (
        <Item grow minWidth={300}>
            <Box textAlign={"left"} mt={{ xs: 2, md: 0 }} my={2}>
                <SocialProvider useStyles={useMoonSocialLinkStyles}>
                    <SocialLink
                        brand={"FacebookCircle"}
                        href={"https://www.facebook.com/valleguadalupebaja"}
                    />
                    <SocialLink
                        brand={"Instagram"}
                        href={
                            "https://www.instagram.com/valleguadalupebaja/?hl=en"
                        }
                    />
                    <SocialLink
                        brand={"GooglePlus"}
                        href={
                            "https://www.youtube.com/channel/UCWO_aqZ56WezANfJEg1JjMQ"
                        }
                    />
                    <SocialLink
                        brand={"LinkedIn"}
                        href={
                            "https://www.linkedin.com/company/valle-guadalupe-baja/?viewAsMember=true"
                        }
                    />
                </SocialProvider>
            </Box>
        </Item>
    );

    const styledBannerSocial = (
        <Item wrap mt={1} grow={2} position={"bottom"}>
            <Row alignItems={"left"}>
                <Item className={classes.logo}>
                    <Logo height={"30px"} />
                </Item>
                <Item>
                    <Typography noWrap variant={"h6"} color={"textSecondary"}>
                        Valle Guadelupe Baja
                    </Typography>
                </Item>
            </Row>
            <Row className={classes.socialMedia} alignItems={"left"}>
                {styledSocialMedia}
            </Row>
        </Item>
    );

    const styledFooterNav = (
        <Item wrap mt={2} grow={2} position={"bottom"}>
            <NavMenu className={classes.navMenu}>
                <NavItem as={Link} to={"/contact"}>
                    Contact Us
                </NavItem>
                <NavItem as={Link} to={"/privacy"}>
                    Privacy Policy
                </NavItem>
                <NavItem as={Link} to={"/tos"}>
                    Terms & Conditions
                </NavItem>
            </NavMenu>
        </Item>
    );

    const styledEmailForm = (
        <Box mt={{ xs: 2, md: 0 }} className={classes.subBox} my={2}>
            <FormLabel htmlFor={"newsletter"} className={classes.newsletter}>
                Get notified of new venues and recommendations
            </FormLabel>
            <EmailSubscribe
                onSubmit={async (email) => {
                    await addToMailchimp(email);
                    setConfirmed(true);
                }}
                useStyles={useBootstrapEmailSubscribeStyles}
                inputClearedAfterSubmit
            >
                <EmailTextInput
                    id={"newsletter"}
                    placeholder="Enter your email"
                    className={classes.email}
                />
                <SubmitButton className={"submit"}>Subscribe</SubmitButton>
            </EmailSubscribe>
        </Box>
    );

    const styledCopyright = (
        <Typography
            component={"p"}
            variant={"caption"}
            color={"textSecondary"}
            className={classes.copyr}
            noWrap
        >
            &copy;&nbsp;
            {new Date().getFullYear()}&nbsp;
            {companyName} All right reserved
        </Typography>
    );

    return (
        <footer className="footer">
            <Snackbar
                open={confirmed}
                autoHideDuration={6000}
                message="Subscribed"
                onClose={handleClose}
            />
            <Container>
                <Box pt={8} pb={2}>
                    <Divider />
                </Box>
                <Container maxWidth="md">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        wrap="nowrap"
                        rowSpacing={1}
                    >
                        {socialproof.map(({ node: c }, index) => (
                            <Grid item key={index}>
                                <Container maxWidth="xs">
                                    {c.name.includes("facebook") ? (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={
                                                "https://www.facebook.com/valleguadalupebaja/reviews"
                                            }
                                        >
                                            <CardMedia
                                                image={getSrc(
                                                    c.childImageSharp
                                                )}
                                                component="img"
                                                alt="social proof image"
                                            />
                                        </a>
                                    ) : (
                                        <CardMedia
                                            image={getSrc(c.childImageSharp)}
                                            component="img"
                                            alt="social proof image"
                                        />
                                    )}
                                </Container>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <Grid
                    container
                    spacing={2}
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    <Grid item xs={12} md={6}>
                        {styledBannerSocial}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {styledEmailForm}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {styledFooterNav}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {styledCopyright}
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
};

Footer.displayName = "Footer";

export default Footer;
