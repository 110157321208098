import React from "react";
import { Link } from "gatsby-theme-material-ui";

import { useTheme, useMediaQuery } from "@material-ui/core";

import NavItem from "./NavItem";
import MyDrawer from "./Drawer";

import Logo from "../assets/svg/logo.svg";

import navbarList from "./navItems";

const NavbarClass = [
    "navbar",
    "navbar-expand-md",
    "sticky-top",
    "custom-navbar",
];

const Header = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <nav id="m-navbar" className={`${NavbarClass.join(" ")} navbar-night`}>
            <div className="container">
                <button type="button" className="navbar-brand btn btn-default">
                    <div className="logo">
                        <Link to="/">
                            <Logo height={"100px"} />
                        </Link>
                    </div>
                </button>
                {isMobile ? (
                    <MyDrawer />
                ) : (
                    <div
                        className="collapse navbar-collapse flex-row-reverse"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav mr-2">
                            {navbarList.map((item) => (
                                <NavItem
                                    url={item.href}
                                    name={item.title}
                                    list={item.list}
                                    key={item.href}
                                />
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Header;
