import React, { useState } from "react";
import { IconButton, Link } from "gatsby-theme-material-ui";
import { makeStyles, Drawer, List, ListItem, ListItemText } from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import navbarList from './navItems';

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none",
        color: "#006837",
        fontSize: "20px",
    },
    icon: {
        color: "#006837",
    },
}));

const MyDrawer = () => {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
        <>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <List>
                    {navbarList.map((item) => (
                        <ListItem onClick={() => setOpenDrawer(false)}>
                            <ListItemText>
                                <Link to={item.href}>{item.title}</Link>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <IconButton
                onClick={() => setOpenDrawer(!openDrawer)}
                className={classes.icon}
            >
                <MenuIcon />
            </IconButton>
        </>
    );
};
export default MyDrawer;
