const navbarList = [
    {
        href: "/",
        title: "Home",
    },
    {
        href: "/grouptrips/",
        title: "Group Tours",
    },
    {
        href: "/privatetrips/",
        title: "Valle Packages",
    },
    {
        href: "/transportation/",
        title: "Transportation",
    },
    {
        href: "/blogs/",
        title: "Blog & Press",
    },
    {
        href: "/contact/",
        title: "Contact Us",
    },
    {
        href: "/info/",
        title: "Q & A",
    }
];
export default navbarList;
