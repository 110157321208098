import React from "react";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "hooks";

const Head = () => {
    const {
        companyName,
        siteDescription,
        siteThemeColor,
        siteKeyword,
        googleSiteVerification,
    } = useSiteMetadata();
    return (
        <Helmet
            defaultTitle={companyName}
            titleTemplate={`%s | ${companyName}`}
        >
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
            <meta name="description" content={siteDescription} />
            <meta name="keyword" content={siteKeyword} />
            <meta name="theme-color" content={siteThemeColor} />
            <meta
                name="msapplication-navbutton-color"
                content={siteThemeColor}
            />
            <meta name="google-site-verification" content="n1Foa4eOhCrMKVmsO4u_PkbJFIzJgYRXAPHQW15ROvM" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content={siteThemeColor}
            />
            <link
                rel="alternate"
                type="application/atom+xml"
                title={companyName}
                href="/atom.xml"
            />
            <meta
                name="google-site-verification"
                content={googleSiteVerification}
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.0.0-beta/css/bootstrap.min.css"
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.12.0/styles/solarized-light.min.css"
            />
        </Helmet>
    );
};

export default Head;
